<template>
  <v-data-table
    :page="page"
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :items-per-page="itemsPerPage"
    :item-class="getItemClass"
    :server-items-length="totalCount"
    :options.sync="options"
    :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
  >
    <template v-slot:[`item.price`]="{ item }">
      {{ item.buyingPrice ? item.buyingPrice : item.sellingPrice }}
    </template>

    <template v-slot:[`item.count`]="{ item }">
      {{ item.refundCount ? item.refundCount : item.count }}
    </template>

    <template v-slot:[`item.orderDate`]="{ item }">
      {{ convertDate(item.orderDate) }}
    </template>

    <template v-slot:[`item.refundDate`]="{ item }">
      {{ convertDate(item.refundDate) }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        v-if="canUpdate && !item.isRefund"
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-menu offset-y v-if="canDelete || canRefund">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>

        <v-list dense class="pt-1 pb-1">
          <v-list-item
            @click="deleteItem(item)"
            class="gray--text text--darken-3"
            v-if="canDelete"
          >
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="body-2 font-weight-regular">
              Sil
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    isFetchingItems: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Məhsul", value: "product", sortable: false },
        { text: "Sayı", value: "count", sortable: false },
        { text: "Əməliyyat növü", value: "operation", sortable: false },
        {
          text: "Sifariş tarixi",
          value: "orderDate",
          sortable: false,
        },
        {
          text: "Sifariş verən əməkdaş",
          value: "orderedUser",
          sortable: false,
        },
        { text: "Alıcı", value: "buyer", sortable: false },
        { text: "Satıcı", value: "seller", sortable: false },
        {
          text: "Qiyməti",
          value: "price",
          sortable: false,
        },

        { text: "Təsviri", value: "description", sortable: false },

        {
          text: "Geri qaytarılma tarixi",
          value: "refundDate",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,

          align: "end",
        },
      ],
      options: {},
    };
  },
  watch: {
    options: {
      handler(value) {
        const { page, itemsPerPage } = value;
        if (page > 0 || itemsPerPage >= 10) {
          this.$emit("optionsChanged", {
            page: page,
            itemsPerPage: itemsPerPage,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    editItem(item) {
      this.$emit("editItem", item);
    },
    deleteItem(item) {
      this.$emit("deleteItem", item);
    },
    getItemClass(item) {
      if (!item.isActive) {
        return "red accent-1";
      }
      return "";
    },
    convertDate(date) {
      if (date != null) return this.$moment(date).format("DD.MM.YYYY");
      else return "-";
    },
  },
  computed: {
    canDelete() {
      return this.$store.getters.inRole("Admin");
    },
    canUpdate() {
      return this.$store.getters.inRole("ProductOrder:edit");
    },
  },
};
</script>
