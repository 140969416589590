<template>
  <v-container fluid class="down-top-padding">
    <div id="itemsPage" class="page">
      <div class="page__header ">
        <Toolbar
          :showFiltersPanel="showFiltersPanel"
          @showFiltersPanelChanged="handleShowFiltersPanelChanged"
          @toggleBuyDialog="toggleBuyDialog"
          @toggleSaleDialog="toggleSaleDialog"
          @toggleDialog="toggleDialog"
        />
      </div>
      <div class="page__body pa-4">
        <div class="mb-4" v-show="showFiltersPanel">
          <v-row class="my-0">
            <v-col md="12">
              <Filters
                @filtersChanged="handleFiltersChanged"
                :products="products"
                :users="users"
              />
            </v-col>
          </v-row>
        </div>

        <v-dialog v-model="buyDialog" persistent scrollable max-width="1200px">
          <BuyForm
            v-if="buyDialog"
            @formSubmit="handleFormSubmit"
            :toggleBuyDialog="toggleBuyDialog"
            :isSubmittingForm="isSubmittingForm"
            :selectedItem="selectedItem"
            :products="products"
            :users="users"
          />
        </v-dialog>

        <v-dialog v-model="saleDialog" persistent scrollable max-width="1200px">
          <SaleForm
            v-if="saleDialog"
            @formSubmit="handleFormSubmit"
            :toggleSaleDialog="toggleSaleDialog"
            :isSubmittingForm="isSubmittingForm"
            :selectedItem="selectedItem"
            :products="products"
            :users="users"
          />
        </v-dialog>

        <v-dialog v-model="dialog" persistent scrollable max-width="1200px">
          <RefundForm
            v-if="dialog"
            @refundFormSubmit="handleRefundFormSubmit"
            :toggleDialog="toggleDialog"
            :isSubmittingForm="isSubmittingForm"
            :selectedItem="selectedItem"
            :products="products"
            :users="users"
          />
        </v-dialog>

        <List
          :page="page"
          :items="items"
          :totalCount="totalCount"
          :isFetchingItems="isFetchingItems"
          :itemsPerPage="itemsPerPage"
          @editItem="handleEditItem"
          @deleteItem="handleDeleteItem"
          @optionsChanged="handleOptionsChanged"
        />
      </div>
      <ConfirmationPopup
        id="item-delete-modal"
        v-if="itemToDelete"
        title="Əməliyyatın silinməsi"
        :dialog="confirmationDialog"
        :loading="isDeletingItem"
        @confirm="handleDeleteConfirmation"
        @cancel="handleDeleteCancellation"
      >
        <span class="font-weight-bold"> {{ itemToDelete.operation }}ı </span>
        silmək istədiyinizə əminsiniz?
      </ConfirmationPopup>
    </div>
  </v-container>
</template>

<script>
import API from "@/services";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import List from "./components/List/index.vue";
import BuyForm from "./components/Form/BuyForm.vue";
import SaleForm from "./components/Form/SaleForm.vue";
import RefundForm from "./components/Form/RefundForm.vue";
import Toolbar from "./components/Toolbar/index.vue";
import Filters from "./components/Toolbar/components/Filters.vue";
import bus from "../../../event-bus";

export default {
  components: {
    List,
    BuyForm,
    SaleForm,
    RefundForm,
    Toolbar,
    ConfirmationPopup,
    Filters,
  },
  data() {
    return {
      dialog: false,
      buyDialog: false,
      saleDialog: false,
      confirmationDialog: false,
      selectedItem: null,
      itemToDelete: null,
      page: 1,
      isFetchingItems: false,
      isSubmittingForm: false,
      isDeletingItem: false,
      totalCount: 0,
      itemsPerPage: 10,
      items: [],
      filters: {},
      showFiltersPanel: false,
      products: [],
      users: [],
    };
  },
  async created() {
    this.products = await API.fetchAllProducts();
    this.users = await API.fetchAllUsers();
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      this.isFetchingItems = true;
      API.fetchProductOrders({
        limit: this.itemsPerPage,
        offset: (this.page - 1) * this.itemsPerPage,
        ...this.filters,
      })
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməliyyatların siyahısını əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleFormSubmit(formData) {
      this.isSubmittingForm = true;
      const request = this.selectedItem
        ? API.updateProductOrder(this.selectedItem.id, formData)
        : API.insertProductOrder(formData);

      request
        .then(() => {
          if (formData.operationType == 1) this.toggleBuyDialog();
          else if (formData.operationType == 2) this.toggleSaleDialog();
          this.fetchItems();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməliyyatı aparmaq mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isSubmittingForm = false;
        });
    },
    handleRefundFormSubmit(formData) {
      this.isSubmittingForm = true;
      const request = API.insertRefundProductOrder(formData);

      request
        .then(() => {
          this.toggleDialog();
          this.fetchItems();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməliyyatı aparmaq mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isSubmittingForm = false;
        });
    },
    handleEditItem(item) {
      this.isFetchingItems = true;
      API.fetchProductOrderById(item.id)
        .then((response) => {
          this.selectedItem = response;
          if (this.selectedItem.operationType == 1) this.buyDialog = true;
          else if (this.selectedItem.operationType == 2) this.saleDialog = true;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Məlumatı əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleRefundItem(item) {
      this.isFetchingItems = true;
      API.fetchProductOrderById(item.id)
        .then((response) => {
          this.selectedItem = response;
          this.dialog = true;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Məlumatı əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleDeleteItem(item) {
      this.itemToDelete = item;
      this.confirmationDialog = true;
    },
    handleDeleteConfirmation() {
      this.isDeletingitem = true;
      if (!this.itemToDelete.isRefund) {
        API.deleteProductOrder(this.itemToDelete.id)
          .then(() => {
            this.confirmationDialog = false;
            this.itemToDelete = null;
            this.fetchItems();
          })
          .catch(() => {
            bus.$emit("notificationMessage", {
              color: "error",
              text: "Əməliyyatı silmək mümkün olmadı.",
            });
          })
          .finally(() => {
            this.isDeletingItem = false;
          });
      } else {
        API.deleteRefundProductOrder(this.itemToDelete.id)
          .then(() => {
            this.confirmationDialog = false;
            this.itemToDelete = null;
            this.fetchItems();
          })
          .catch(() => {
            bus.$emit("notificationMessage", {
              color: "error",
              text: "Əməliyyatı silmək mümkün olmadı.",
            });
          })
          .finally(() => {
            this.isDeletingItem = false;
          });
      }
    },
    handleDeleteCancellation() {
      this.confirmationDialog = false;
      this.itemToDelete = null;
    },
    handleFiltersChanged(filters) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.page = 1;
        this.filters = { ...filters };
        this.fetchItems();
      }
    },
    toggleDialog() {
      this.dialog = !this.dialog;
      if (!this.dialog) {
        this.selectedItem = null;
      }
    },
    toggleBuyDialog() {
      this.buyDialog = !this.buyDialog;
      if (!this.buyDialog) {
        this.selectedItem = null;
      }
    },
    toggleSaleDialog() {
      this.saleDialog = !this.saleDialog;
      if (!this.saleDialog) {
        this.selectedItem = null;
      }
    },
    handleOptionsChanged(item) {
      if (this.page !== item.page || this.itemsPerPage !== item.itemsPerPage) {
        this.page = item.page;
        this.itemsPerPage = item.itemsPerPage;

        this.fetchItems();
      }
    },
    handleShowFiltersPanelChanged(item) {
      this.showFiltersPanel = item;
    },
  },
};
</script>
