<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        Geri qaytarılma
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createItemForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleRefundFormSubmit"
      >
        <v-row>
          <v-col cols="12">
            <v-radio-group
              class="my-0 pt-0"
              v-model="formData.operationType"
              row
              hide-details
              :rules="formRules.operationType"
            >
              <v-radio
                class="ml-2"
                label="Satınalmanın geri qaytarılması"
                :value="3"
              ></v-radio>
              <v-radio
                class="ml-2"
                label="Satışın geri qaytarılması"
                :value="4"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="3" class="mb-3">
            <v-autocomplete
              label="Məhsul"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.productId"
              required
              :items="products"
              :rules="formRules.product"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="number"
              label="Geri qaytarılan say"
              filled
              dense
              required
              min="0"
              v-model="formData.refundCount"
              :rules="formRules.refundCount"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Alıcı"
              filled
              dense
              v-model="formData.buyer"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Satıcı"
              filled
              dense
              v-model="formData.seller"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="mb-3" v-if="isBuying">
            <v-text-field
              label="Qiyməti"
              filled
              dense
              v-model="formData.buyingPrice"
            >
            </v-text-field>
          </v-col>

          <v-col cols="3" class="mb-3" v-if="!isBuying">
            <v-text-field
              label="Qiyməti"
              filled
              dense
              v-model="formData.sellingPrice"
            >
            </v-text-field>
          </v-col>

          <v-col cols="3" class="mb-3">
            <v-autocomplete
              label="Sifariş edən əməkdaş"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.orderedUserId"
              :items="users"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="date"
              label="Sifariş tarixi"
              filled
              dense
              v-model="formData.orderDate"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-textarea
              type="text"
              label="Təsviri"
              filled
              dense
              required
              auto-grow
              rows="1"
              v-model="formData.description"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createItemForm"
        color="success"
        depressed
      >
        Yarat
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: false,
      default: () => null,
    },
    products: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formValidation: false,
      formData: {
        productId: null,
        buyer: null,
        seller: null,
        buyingPrice: null,
        sellingPrice: null,
        count: 0,
        orderedUserId: null,
        orderDate: null,
        description: null,
        operationType: null,
        refundCount: 0,
      },
      formRules: {
        product: [(v) => !!v || "Geri qaytarılan məhsulun adını daxil edin"],
        refundCount: [
          (v) => (!!v && v > 0) || "Geri qaytarılan məhsulun sayını daxil edin",
        ],
        operationType: [(v) => !!v || "Əməliyyat növünü seçin"],
      },
    };
  },
  computed: {
    isBuying() {
      return this.formData.operationType == 3;
    },
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
          this.formData.orderDate = this.convertDate(newVal.orderDate);
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleRefundFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("refundFormSubmit", this.formData);
      }
    },
    resetFormData() {
      this.formData = {
        productId: null,
        buyer: null,
        seller: null,
        buyingPrice: null,
        sellingPrice: null,
        count: 0,
        orderedUserId: null,
        orderDate: null,
        description: null,
        operationType: null,
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
    convertDate(date) {
      if (date != null) return this.$moment(date).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>
