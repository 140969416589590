<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        {{ isEditMode ? "Satınalmanın redaktəsi" : "Yeni satınalma" }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createItemForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="3" class="mb-3">
            <v-autocomplete
              label="Məhsul"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.productId"
              :rules="formRules.product"
              required
              :items="products"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Alıcı"
              filled
              dense
              v-model="formData.buyer"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              type="text"
              label="Satıcı"
              filled
              dense
              v-model="formData.seller"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="number"
              label="Sayı"
              filled
              dense
              required
              min="0"
              v-model="formData.count"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="mb-3">
            <v-text-field
              label="Qiyməti"
              filled
              dense
              v-model="formData.buyingPrice"
            >
            </v-text-field>
          </v-col>

          <v-col cols="3" class="mb-3">
            <v-autocomplete
              label="Sifariş edən əməkdaş"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.orderedUserId"
              :items="users"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-text-field
              type="date"
              label="Sifariş tarixi"
              filled
              dense
              v-model="formData.orderDate"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-textarea
              type="text"
              label="Təsviri"
              filled
              dense
              required
              auto-grow
              rows="1"
              v-model="formData.description"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleBuyDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createItemForm"
        color="success"
        depressed
      >
        {{ isEditMode ? "Redaktə et" : "Yarat" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleBuyDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: false,
      default: () => null,
    },
    products: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formValidation: false,
      formData: {
        productId: null,
        buyer: null,
        seller: null,
        buyingPrice: null,
        count: 0,
        orderedUserId: null,
        orderDate: null,
        description: null,
        operationType: 1,
      },
      formRules: {
        product: [(v) => !!v || "Məhsul adını daxil edin"],
      },
    };
  },
  computed: {
    isEditMode() {
      return (
        this.selectedItem && this.selectedItem.id && this.selectedItem.id > 0
      );
    },
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
          this.formData.orderDate = this.convertDate(newVal.orderDate);
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
    resetFormData() {
      this.formData = {
        productId: null,
        buyer: null,
        seller: null,
        buyingPrice: null,
        count: 0,
        orderedUserId: null,
        orderDate: null,
        description: null,
        operationType: 1,
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
    convertDate(date) {
      if (date != null) return this.$moment(date).format("YYYY-MM-DD");
      else return null;
    },
  },
};
</script>
