<template>
  <div class="border-all rounded">
    <div class="pt-4 px-4">
      <v-row class="my-0">
        <v-col cols="6" class="mb-3">
          <v-text-field
            type="text"
            label="Alıcı"
            filled
            dense
            v-model="filters.buyer"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="6" class="mb-3">
          <v-text-field
            type="text"
            label="Satıcı"
            filled
            dense
            v-model="filters.seller"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="6" class="mb-3">
          <v-text-field
            type="text"
            label="Təsviri"
            filled
            dense
            v-model="filters.description"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="mb-3">
          <v-autocomplete
            label="Məhsul"
            filled
            dense
            clearable
            hide-details
            item-text="value"
            item-value="id"
            v-model="filters.productId"
            :items="products"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-2">
          <span class="caption input-color ls-0">Geri qaytarılma?</span>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-radio-group
            v-model="filters.isRefund"
            row
            class="mt-0"
            hide-details
            dense
          >
            <v-radio label="Hə" value="true"></v-radio
            ><v-radio label="Yox" value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
    <div class="border-top py-3 px-4">
      <div class="d-flex justify-end">
        <v-btn @click="clear" class="mr-3" color="gray lighten-3" depressed>
          Təmizlə
        </v-btn>
        <v-btn @click="search" color="primary" depressed>
          Axtar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    products: {
      type: Array
    },
  },
  data() {
    return {
      filters: {
        buyer: "",
        seller: "",
        buyingPrice: "",
        sellingPrice: "",
        description: "",
        isRefund: "",
        productId: null,
      },
    };
  },
  methods: {
    search() {
      this.$emit("filtersChanged", this.filters);
    },
    clear() {
      this.$emit("filtersChanged", {});
      this.filters = {
        buyer: "",
        seller: "",
        buyingPrice: "",
        sellingPrice: "",
        description: "",
        isRefund: "",
        productId: null,
      };
    },
  },
};
</script>
